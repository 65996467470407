body {
    margin: 0;

    width: 100%;
    font-family: "Poppins-regular", sans-serif;
    background-color: #ffffff;
    color: #231f20;

    /* p {
        font-family: "Poppins-bold", sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
    } */
}

/*--- FONTS ---*/
@font-face {
    font-family: "Poppins-regular";
    src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-bold";
    src: url("../fonts/Poppins-Bold.ttf");
}

a {
    text-decoration: none;
    color: black;
}

/* TO TOP */
.toTop {
    background-color: #f49f22;
    width: 45px;
    height: 45px;
    box-sizing: border-box;
    text-align: center;
    padding: 10px 10px 10px 10px;
    position: fixed;
    bottom: 12px;
    right: 12px;
    cursor: pointer;
    border-radius: 25px;
    display: block;
    z-index: 10;
    border: none;
}

/* Scroll bar */
/* width */
div::-webkit-scrollbar {
    /*width: 5px;*/
}

/* Track */
div::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
div::-webkit-scrollbar-thumb {
    background: #f49f21;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
    background: #d68205;
}

/* End of scroolbar */

/* BUTTON */
.btn-primary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    gap: 32px;

    min-width: 121px;
    min-height: 61px;
    max-height:fit-content;
    left: 37px;
    top: 55px;

    background: #f49f21;
    border-radius: 16px;
    color: white;
    border: 2px solid #f49f21;

    /* font 
    font-family: Poppins;*/
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

.btn-primary:hover {
    background: #d68205;
    border: 2px solid #d68205;
}

.btn-secondary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    gap: 32px;

    min-width: 121px;
    min-height: 61px;
    max-height:fit-content;
    left: 37px;
    top: 55px;

    background: white;
    border-radius: 16px;
    color: #f49f21;
    font-size: 18px;
    border: 2px solid #f49f21;

    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

.btn-secondary:hover {
    background: #f49f2126;
}

/* END OF BUTTON */

/* TOP BANNER */
.banner {
    height: 40px;
    background-color: #f49f21;
    color: white;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;

    font-family: "Work Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 40px;
}

.banner-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0 90px;
}

.banner-section2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 12px;
    padding: 0 90px;
}

.banner-el {
    margin: 0 10px;
    color: white;
    align-self: center;

    svg {
        transform: translate(0, 2px);
        margin-right: 8px;
    }
}

/* END OF TOP BANNER */

/* HEADER */
.header {
    /*height: 212px;*/
}

.header-img {
    padding: 30px;
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    display:flex;
    flex-direction:row;

    .m-show{
        display:none;
    }

}

.navigation {
    margin-bottom: 10px;
    height: 40px;
    background-color: white;
    color: black;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;

    .nav-section {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        list-style-type: none;
    }

    .nav-el {
        margin: 0 20px;
        color: black;
        align-self: center;

        /* for svg*/
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .nav-el:hover,
    .nav-el.active {
        color: #f49f21;
    }

    .menu-item-has-children:hover .sub-menu {
        display: block;
    }

    .sub-menu {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 3;
    }

    .sub-menu {
        position: relative;
        display: inline-block;
        left: 244px;
        top: 0px;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content{
        display: none;
        position: absolute;
        background-color: #f49f21;
        /*min-width: 160px;*/
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        list-style-type: none;
        padding-left: 0px;
        border-radius: 10px;
        width: 240px;
    }

    .sub-menu {
        display: none;
        position: absolute;
        background-color: #f49f21;
        /*min-width: 160px;*/
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        list-style-type: none;
        padding-left: 0px;
        border-radius: 10px;
        width: 300px;
    }

    .dropdown-content a{
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        width: 200px;
        font-size: 14px;

        border-top: 0;
        margin: 0;
        padding: 10px 0 10px 0;
        margin: 0 20px 0 20px;
        border-bottom: 1px solid #dce0e0;
    }
   
    .sub-menu a {
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        width: 260px;
        font-size: 14px;

        border-top: 0;
        margin: 0;
        padding: 10px 0 10px 0;
        margin: 0 20px 0 20px;
        border-bottom: 1px solid #dce0e0;
    }

    .sub-menu li:last-child a{
        border-bottom: none !important;
    }

    .dropdown-content li:last-child {
        border-bottom: none;
    }

    .dropdown-content li a{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .dropdown-content a:hover,
    .sub-menu a:hover {
        color: black;

        -webkit-transition: color 0.3s ease-out;
        -moz-transition: color 0.3s ease-out;
        -o-transition: color 0.3s ease-out;
        transition: color 0.3s ease-out;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* toggle image of arrow */
    .toggle_arrow a img:nth-child(2) {
        display: none;
    }

    .toggle_arrow:hover a img:nth-child(1) {
        display: none;
    }

    .toggle_arrow:hover a img:nth-child(2) {
        display: block;
    }
}

.navigation-mobile{
    background-color: #f49f21;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    color:white;

    font-family: "Poppins-regular", sans-serif;

    .mobile-exit{
        display:flex;
        justify-content: flex-end;
    }

    ul {
        all: unset;
        list-style-type: none;
        margin: 0px 10px;
    }

    .nav-section{
        
        list-style-type: none;

        li {
            margin: 0 20px 15px 20px;
        }

        li a {
            font-family: "Poppins-regular";
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 1;
            text-transform: none;
            color:white;
        }

        .dropdown-content li.toggle_arrow a{
            width:70px;
            
            border-bottom: 1px solid #dce0e0;
            padding-top:10px;
        }

        .dropdown-content li a{
            justify-content: space-between;
            display: flex;
        }

      
        .dropdown.active li,.dropdown-content li.active ul.sub-menu{
            display:block;
        }

        .dropdown li, .dropdown-content li ul.sub-menu{
            display:none;
        }

        .sub-menu li{
            margin:0 0 0 10px;
            width: 100%;
        }

        .sub-menu li{
            margin:0 0 0 10px;
            width: 100%;
            
        }

        .sub-menu ul{
            margin:0;
        }

        .dropdown-content .sub-menu li a{
            width:100%;
            font-size: 16px;
            border-bottom: 1px solid #dce0e0;
            padding-top:10px;
            padding-bottom:10px;
        }

        .dropdown-content:last-child{
            margin:0;
        }
    }
}

/* END OF HEADER */

/* NAVIGATION FROM AGRA */

.nav_wrapper_inner {
    display: table-cell;
    vertical-align: middle;
}

/* MAIN SECTION */
.main-section {
    text-transform: uppercase;
    height: 463px;
    width: 100%;

    background-repeat: no-repeat;
    background-size: cover;

    .title-section {
        color: rgb(255, 255, 255);
        height: 100%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .main-title {
            font-size: 80px;
            font-weight: 600;
            line-height: 64px;
            text-align: center;
            margin: auto 0 0 0;
            padding: 0 1rem;
        }
        .sub-title {
            font-size: 32px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            margin: 20px 0 auto 0;
        }
    }
}

/* END OF MAIN SECTION */

/* SUBPAGE SECTION */
.subpage-section {
    @extend .main-section;
    height: 242px;
}

/* CATEGORY SECTION */
.category-section {
    text-transform: uppercase;
    background-color: #f3f3f3;
    min-height: 627px;

    .info-section {
        max-width: 1176px;
        padding: 30px;
        margin: auto;
    }

    .title-section {
        font-size: 20px;
        font-weight: 400;
        line-height: 64px;
        text-align: center;
        margin: 0;
        padding-top: 40px;
    }

    .about {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
    }

    .add-space {
        margin-right: 30px;
    }

    .category-cards {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        gap: 10px;
    }

    .category-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
        gap: 25px;

        width: 270px;
        height: 140px;

        background: #ffffff;
        border-radius: 16px;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;

        background-color: #ffffff;

        img {
            color: #231f20;
            margin-top: 20px;
            height: 48px;
            object-fit: none;
        }

        .icon-container {
            width: 50px;
            height: 50px;
        }

        .icon-container img {
            width: 100%;
            height: auto;
        }

        .title {
            text-transform: none;
            margin: 10px auto;
            width: 90%;

            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;

            align-items: center;
            text-align: center;

            color: #000000;

            /* Inside auto layout */
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
        }
    }

    .category-card:hover {
        background-color: #f49f21;
        cursor: pointer;
        .title {
            color: white;
        }
    }
    .category-card img:nth-child(2) {
        display: none;
    }

    .category-card:hover img:nth-child(1) {
        display: none;
    }

    .category-card:hover img:nth-child(2) {
        display: block;
    }
}

/* END OF CATEGORY SECTION */

/* COUNCIL SECTION */
.council-section {
    max-width: 1176px;
    padding: 30px;
    margin: auto;

    h2 {
        color: #f49f21;
        font-size: 20px;
        font-weight: 600;
        line-height: 64px;
        text-transform: uppercase;
    }

    .member {
        width: 300px;
        .name {
            font-weight: 600;
            font-size: 24px;
            line-height: 48px;
            text-transform: uppercase;
            margin: 8px 0;
        }

        .position {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            margin: 0;
        }
    }

    .divider {
        border-bottom: 1px solid #000000;
        margin: 20px 0;
    }

    .members {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Three equal columns */
        gap: 20px 40px; /* Optional: Adds spacing between items */
        padding-bottom: 40px;
    }
}

/* END OF COUNCIL SECTION */

/* RECORD SECTION */
.record-section {
    max-width: 1176px;
    padding: 30px;
    margin: auto;

    .foreword {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        max-width: 640px;
    }

    h1 {
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        text-transform: uppercase;
        margin-top: 50px;
    }

    h2 {
        color: #f49f21;
        font-size: 20px;
        font-weight: 600;
        line-height: 64px;
        text-transform: uppercase;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
    }

    .subtitle{
        margin: 0;
    }

    .member {
        width: 300px;
        .name {
            font-weight: 600;
            font-size: 24px;
            line-height: 48px;
            text-transform: uppercase;
            margin: 8px 0;
        }

        .position {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            margin: 0;
        }
    }

    .divider {
        border-bottom: 1px solid #000000;
        margin: 20px 0;
    }

    .members {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Three equal columns */
        gap: 20px 40px; /* Optional: Adds spacing between items */
        padding-bottom: 40px;
    }

    /* .row {
        border-bottom: 5px solid #e0e2e6;
    } */

    .collaborators {
        display: flex;
        flex-direction: row;
        gap: 20%;
        padding-bottom: 40px;
    }

    .stats {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Three equal columns */

        gap: 20px 40px; /* Optional: Adds spacing between items */
        .stats-item {
            max-width: 240px;
            .stats-item-number {
                font-weight: 600;
                font-size: 36px;
                line-height: 48px;
                margin: 0;
            }

            .stats-item-title {
                @extend .default-text;
                margin: 0;
            }
        }
    }
}

.default-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.country {
    img {
        padding: 0 10px;
    }
}

.records-table {
    text-align: left;
    border-collapse: collapse;
    margin-bottom: 40px;

    tr{
        vertical-align: middle;
    }

    th {
        padding: 6px;
        border: 1px solid #e0e2e6;
        border-top: none;
        background-color: #ffffff;
    }
    th:first-child, th:last-child {
        border-left: none;
    }
    tr td{
        border-left: none;
    }
    tr td:last-child,
    tr th:last-child {
        border-right: none;
    }
    tr:last-child td{
        border-bottom: none;
    }

    td {
        padding: 6px;
        border: 1px solid #e0e2e6;;
    }

    img {
        padding-right: 4px;
        vertical-align: middle;
    }

    tr:nth-child(odd) {
        background-color: #FAFAFA;
    }

    
}

/* END OF RECORD SECTION */

/* EDITORIAL SECTION */
.editorial-section {
    max-width: 1176px;
    padding: 30px;
    margin: auto;
    margin-bottom: 50px;

    .article {
        .company {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-transform: uppercase;
        }

        .title {
            font-weight: 600;
            font-size: 48px;
            line-height: 48px;
            text-transform: uppercase;
            margin: 0;
        }

        .author {
            font-weight: 600;
            font-size: 20px;
            line-height: 64px;
            margin: 10px 0 20px;
        }

        .content {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
        }
    }

    .divider {
        border-bottom: 1px solid #000000;
        margin: 20px 0;
    }
}

/* END OF EDITORIAL SECTION */

/* DETAILS SECTION */
.details-wrapper {
    .details-section {
        max-width: 1176px;
        padding: 30px;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 40px;

        .details-left {
            width: 50%;
            min-height: 50vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .details-title {
                text-transform: uppercase;
                //margin-bottom:100px;

                .origin {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        margin: 0;
                    }
                }

                h1 {
                    font-size: 48px;
                    font-weight: 600;
                    line-height: 48px;
                    margin: 10px 0;
                }

                h2 {
                    font-weight: 600;
                    font-size: 20px;
                    /*line-height: 64px;*/ // removed for long groups
                }
            }

            .details-company {
                max-width: 310px;

                a{
                    color: rgb(36, 36, 168);
                }
            }

            .download-qr{
                margin: 3rem 0 6rem 0;
            }
        }

        .details-right {
            width: 50%;

            max-width:552px;
            max-height:552px;

            img{
                max-width:540px;
                max-height:540px;

                width:100%;
                height: auto;
            }

            div{
                background-color: #F3F3F3;
                border-radius: 10px;
            }

            .details-medal-data p{
                margin:4px 0;
            }

            .details-medal{
                width:100%;
                height: 400px;
                margin-bottom:10px;

                display: flex;
                justify-content: center;
                align-items: center;

                img{

                    max-width:300px;
                    max-height:300px;

                    width:100%;
                    height: auto;
                }
            }
            
        }
    }
    .banner {
        width: 100%;
        height: 130px;
        background-color: #f3f3f3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;

        h2 {
            color: black;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 24px;
            line-height: 48px;
        }
    }
}
/* END OF DETAILS SECTION */

/* FOOTER */
.footer-section {
    background-color: #000000;
    width: 100%;
    min-height: 380px;

    .footer-info {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        max-width: 1208px;
        padding: 50px 40px;
        margin: auto;

        gap: 20px;

        color: white;

        ul {
            all: unset;
            list-style-type: none;

            li {
                margin: 0 0 10px 0;
            }

            .contain-w {
                max-width: 390px;
            }
        }

        a {
            color: white;
            padding-left: 0;
            margin-left: 0;
            font-size: 15px;
            line-height: 1.7;
        }

        a:hover {
            color: #f49f21;
        }

        .title {
            margin-top: 10px;
            margin-bottom: 10px;
            border: 0;
            font-size: 18px;

            font-weight: 700;
            letter-spacing: 0px;
            line-height: 1.7;
            text-transform: uppercase;
        }

        .social {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .social-el {
                display: block;
                width: 40px !important;
                height: 40px;
                color: #fff !important;
                background: #000;
                border-radius: 250px;
                line-height: 40px;
                text-align: center;
                margin-right: 10px;
            }
        }
    }

    .footer-desc {
        max-width: 1354px;
        border-top: solid 1px;
        margin: auto;

        p {
            color: #cccccc;
            max-width: 1208px;
            padding: 20px 40px;
            margin: auto;
        }
    }
}
/* END OF FOOTER*/

/* SEARCH SECTION */
.searchSection {
    padding: 80px;
    max-width: 1208px;
    margin: auto;

    .about {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        height: 60px;

        a {
            align-self: center;
            color: #f49f21;
        }
    }

    .add-space {
        margin-right: 30px;
    }
}

.searchTableAgra {
    margin-top: 30px;
    th,
    td {
        border-right: 1px solid rgba(224, 224, 224, 1);
        height: 37px;
        padding: 0px 10px;
        //opacity: 0px;
        margin: 0px;
    }

    tr:hover td {
        background-color: #fdf0dd; 
    }


    tr td {
        height: 37px;
        padding: 0px 10px;
        //opacity: 0px;
        margin: 0px;

        .tooltip {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 220px;
        }
    }


    td:nth-child(5) div{
        display: flex;
        flex-direction: row;
        justify-content: start;
    } 
    td:last-child div{
        display: flex;
        flex-direction: row;
        margin: auto;
    }
}

.searchTableAgraOscar{
    margin-top: 30px;
    th,
    td {
        border-right: 1px solid rgba(224, 224, 224, 1);
        height: 37px;
        padding: 0px 10px;
        //opacity: 0px;
        margin: 0px;
    }

    tr:hover td {
        background-color: #fdf0dd; 
    }


    tr td {
        height: 37px;
        padding: 0px 10px;
        //opacity: 0px;
        margin: 0px;

        .tooltip {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 220px;
        }
    }

}

.table-num-el {
    /*margin-top:20px;
    border-top: 1px solid #E0E2E6;*/

    p {
        margin: 10px 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        color: #c7c7c7;
    }
}

//Prize icons
.icon-reward{
    margin-top: 12px;
    width: 16px;
    height: 16px;
}

.posebno-priznanje{
    background: white;
    border-radius: 50%;
    
    box-shadow: 0 0 0 4px #5A60FF;
    margin:4px;

    width: 8px;
    height: 8px;
    margin-top:14px;
}

.sampijon{
    background: #5A60FF; 
    border-radius: 15px;
}

.v-zlata-nagada{
    background: white;
    border-radius: 50%;
    
    box-shadow: 0 0 0 4px #E7AB0E;
    margin:4px;

    width: 8px;
    height: 8px;
    margin-top:8px;
}

.zlata-nagrada{
    background: #F4C64F; 
    border-radius: 15px;
}

.srebrna-nagrada{
    background: #A8A8A8; 
    border-radius: 15px;
}

.bronasta-nagrada{
    background: #BB800F; 
    border-radius: 15px;
}

/* END OF SEARCH SECTION */

/* BACK TO HOME */
.back-home {
    a {
        align-self: center;
        color: #f49f21;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }
    cursor: pointer;
}


/* ----------------------------------------------------------------------------------------------------------------------- */
@media only screen and (max-width: 1023px) {
    .header-img{
        .m-show{
            display:block;
        }
    }

    
    .navigation.m-hide {
        display: none;
        height:0px;
    
    }

    .searchSection {
        .about {
            flex-direction: column;
            height: unset;

            a {
                text-align: left !important;
                align-self: flex-start;
            }

            button {
                margin: 5px 0;
            }
        }
    }

    .searchTableAgra {
        td:nth-child(1),th:nth-child(1) {
            display: none;
        } 
        td:nth-child(3),th:nth-child(3) {
            display: none;
        } 
        td:nth-child(5),th:nth-child(5) {
            display: none;
        } 
    }

    .details-section{
        flex-direction: column !important;

       .details-right .details-medal img{
            margin:20px !important;
       }

        .details-left, .details-right{
            width:100% !important;
        }

        .details-right{
            margin-bottom: 3rem;
        }
    }

}

@media only screen and (max-width: 864px) {
    .category-section .category-cards {
        justify-content: space-around;
    }
}

@media only screen and (max-width: 768px) {
    .banner-el-hide {
        display: none;
    }

    .main-section {
        .title-section {
            .main-title {
                font-size: 42px;
                line-height: 42px;
            }
            .sub-title {
                font-size: 24px;
                line-height: 24px;
            }
        }
    }

    .category-section {
        .info-section .about {
            flex-direction: column;

            button {
                margin: 5px 0;
            }
        }
    }

    .searchTableAgra, .searchTableAgraOscar {
        

        tr{
            width:90%;
        }

        tr td{
            width:90%;
        }
    }

    .searchSection {
        padding: 2rem;
    }

    .banner-section{
        padding: 0;
    }

    /* body{
        display: flex;
        flex-direction: column;
        align-items: center;
    } */

    .council-section{
        max-width: 80%;
        padding: 20px;
        margin: 0;

        .members{
            display: unset;
        }

        .member{
            width: 260px;
        }

    }

    .record-section{
        
        .members{
            display: unset;
        }

        .member{
            width: 260px;
        }

        .collaborators{
            flex-direction: column;
        }

        .stats{
            .stats-item-number{
                font-size: 24px !important;
            }
        }

        h2{
            line-height: 36px;
        }

        
    }

    .editorial-section{

        .author{
            line-height: 36px !important;
        }

        /* .content{
            text-align: justify;
        } */
    }
}

@media only screen and (max-width: 425px) {
    .banner-el {
        margin: 0 10px;
    }

    .details-section{
        flex-direction: column !important;

        .details-left .details-title h1{
            word-break: break-all;
        }

        .details-right .details-medal img{
            width:70vw !important;
            height:auto;
        }
    }
   
}
